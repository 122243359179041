.chatContainer {
  height: 100%;
  flex-wrap: nowrap !important;

  .messagesWrapper { 
    overflow: hidden;
    flex: 1 1 100%;
  }
  .formWrapper {
    padding-bottom: 11.68vh;
    flex: 0 0 auto;
    box-shadow: 0 -3px 3px 0 rgba(0, 0, 0, 0.1);
  }
}